<template>
  <div
    v-if="content.audio_type"
    :class="`c-audio-element c-audio-element--${audioTheme}`"
    ref="audioElementWrapper"
  >
    <VHeading header="h2"
      :text="content.audio_type.name"
      class="c-audio-element__title o-heading" />
    <div v-show="showPlayer" class="c-audio__wrapper">
      <FHAudioPlayer
        ref="player"
        class="c-audio-element__player"
        type="simple"
        :fileType="content.audio_type.file.mime"
        :fileUrl="content.audio_type.file.url"
        :startTime="playerCurrentTime"
        @timeupdate="onTimeUpdate"
        @init="setPlayer"
      />

      <div class="c-audio-element__chapters">
        <FHVideoChapter
          v-for="chapter in content.audio_type.mediaChapter"
          :key="chapter.id"
          :title="chapter.name"
          :start="parseTime(chapter.start_time)"
          :end="parseTime(chapter.end_time)"
          :current-time="playerCurrentTime"
          @click.native="updateCurrentTime(parseTime(chapter.start_time))"
        />
      </div>
    </div>

    <div v-show="!showPlayer && articlePreview" class="c-audio__placeholder" @click="onPlaceholderClick" @keypress.enter="onPlaceholderClick" tabindex="0" aria-label="Spille lyd">
      <FHButton  class="c-audio__placeholder-btn" tabindex="-1">
        <svg class="c-audio__placeholder-icon" enable-background="new 0 0 494.942 494.942" height="512" fill="#fff" viewBox="0 0 494.942 494.942" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m35.353 0 424.236 247.471-424.236 247.471z"/></svg>
      </FHButton>
      <div class="c-audio__placeholder-text">
        <span>{{ audioTitle }}</span>
        <span v-if="audioChapter">{{ audioChapter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'
import FHAudioPlayer from '@forlagshuset/v-audio-player'
import FHVideoChapter from '@forlagshuset/v-video-chapter'
import FHButton from '@forlagshuset/v-button'

const hmsToSeconds = string => {
    string = string.split('.')[0]
    const p = string.split(':')
    let s = 0
    let m = 1

    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10)
        m *= 60
    }

    return s
}

export default {
  components: {
    VHeading,
    FHButton,
    FHAudioPlayer,
    FHVideoChapter
  },

  data () {
    return {
      player: null,
      playerCurrentTime: 0,
      currentTextTrack: null,
      textTracks: null,
      showPlayer: false,
      audioTitle: '',
      audioChapter: '',
      articlePreview: false
    }
  },

  computed: {
    audioTheme() {
      if (this.index === 0) return 'black'

      return 'default'
    }
  },

  mounted() {
    if (this.index !== 0) {
      this.articlePreview = true
    } else {
      this.getOuterAudioPlayBtn().addEventListener('click', ev => {
        ev.currentTarget.style.display = 'none';
        this.showPlayer = true
        this.player.play()
      })
    }
    this.audioTitle = this.content.audio_type.name

    if (this.content.audio_type.mediaChapter && this.content.audio_type.mediaChapter.length) {
      if (this.content.startTime) {
        const chapter = this.content.audio_type.mediaChapter.filter(mc => mc.start_time === this.content.startTime)

        if (chapter.length) {
          this.audioChapter = chapter[0].name
        } else {
          this.audioChapter = this.content.audio_type.mediaChapter[0].name
        }
      } else {
        this.audioChapter = this.content.audio_type.mediaChapter[0].name
      }
    }
  },

  methods: {
    getOuterAudioPlayBtn() {
      return this.$refs.audioElementWrapper.parentNode.parentNode.parentNode.querySelector('.c-article__audio-play')
    },

    setPlayer(player) {
      this.player = player

      if (this.content.startTime) {
        this.player.currentTime = this.parseTime(this.content.startTime)
      }
    },

    onTextTrackLoad(tracks) {
      this.textTracks = tracks
      this.currentTextTrack = this.textTracks.filter(track => track.language === 'nb')[0] // Look for current language in app
    },

    onTimeUpdate(time) {
      this.playerCurrentTime = time
    },

    updateCurrentTime(time) {
      this.player.currentTime = time
      this.player.play()
    },

    onPlaceholderClick() {
      this.showPlayer = true

      this.$nextTick(() => {
        this.player.play()
        this.player.focus()
      })
    },

    parseTime(time) {
      return hmsToSeconds(time)
    }
  },

  props: {
    content: Object,
    index: Number
  },
}
</script>

<style lang="scss">
.c-audio-element {
  clear: both;
}
.c-audio-element__title {
  display: none;
}
.c-audio-element__chapters {
  margin-top: 1rem;
}

.c-video-chapter {
  border: 1px solid $color-brand;
  margin: 0.5rem;
  padding: 0.1rem 0.7rem;

  .c-audio-element--black & {
    border: 1px solid $color-black;

    &--active {
      background: $color-black;
    }
  }
}

.c-audio__placeholder {
  display: inline-flex;
  align-items: center;
  background: $color-white;
  padding: 1rem;
  border: 2px solid $color-brand;
  border-radius: 3rem;

  cursor: pointer;
}

.c-audio__placeholder-btn {
  position: relative;
  background: $color-brand;
  width: 3rem;
  height: 3rem;
  padding: 0;

  border-radius: 50%;
}

.c-audio__placeholder-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.25rem;
  right: 0;
  margin: 0 auto;
  width: 1.6rem;
  height: 1.6rem;
}

.c-audio__placeholder-text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 2rem;
}

.c-custom-audio-player {
  font-family: 'Circular Pro', sans-serif !important;
  background: $color-brand;
  border-radius: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .c-audio-element--black & {
    background: $color-black;
  }

  .vue-slider-dot-handle {
    background-color: $color-white;
  }

  .vue-slider-process {
    background-color: $color-brand-light;
  }

  .vue-slider-rail {
    background-color: $color-white;
  }

  .c-custom-audio-play__sound-slider {
    background: $color-brand;
  }

  .c-custom-audio-player__button:hover {
    [class^="icon-"]::before {
      color: $color-brand-light !important;
    }
  }
}

.c-video-chapter {
  font-family: 'Circluar Pro', sans-serif;
  font-size: 0.9rem;
  color: $color-black;

  &--active {
    color: $color-white;
    background-color: $color-brand;
  }
}
</style>