<template>
  <div class="c-section-tag u-tac">
    <VHeading header="h3"
      :text="content.name"
    />
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading
  },

  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-section-tag {
  grid-row-start: 1;
  h3 {
    margin: 1rem 0;
  }
}
</style>