<template>
  <FHButton class="c-concept u-px u-py" :class="{'c-concept--pointer': canOpenModal}" @click.native="canOpenModal ? $store.dispatch('modal/setModal', content.concept_type) : false">
    {{ content.name }}
  </FHButton>
</template>

<script>
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHButton
  },

  props: {
    content: Object,
    canOpenModal: Boolean
  }
}
</script>

<style lang="scss">
.c-concept {
  font-family: CircularPro, sans-serif;
  background: $concept-box-bg;
  display: block;
  width: 75%;
  text-align: left;
  font-size: 1.25rem;
  padding: 1rem 1.5rem;
  border: none;
  cursor: auto;

  .c-article__concepts &,
  .c-lesson__concepts & {
    border-radius: 2rem;
    margin: 1rem 0;
  }

  .c-concept-box & {
    width: 100%;
  }

  &--pointer {
    cursor: pointer;
  }
}
</style>